<template>
  <div class="header">
    <div>
      <a
        v-if="hasBackLink"
        class="left-link"
        @click.prevent="back"
        v-html="$t('labels.back')"
      />
    </div>

    <a
      class="logo"
      href="/"
    />

    <router-link
      v-if="link.to"
      class="right-link"
      :to="link.to"
    >
      {{ link.label }}
    </router-link>
  </div>
</template>

<script>
  export default {
    name: 'PageHeader',
    props: {
      link: {
        type: Object,
      },

      hasBackLink: {
        type: Boolean,
      },
    },

    methods: {
      back() {
        this.$router.go(-1);
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "../../../../../laravel/resources/assets/common/sass/mixins/media-queries";
  @import "../../../../../laravel/resources/assets/common/sass/_variables";

  .header {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 16px;
  }

  .left-link {
    cursor: pointer;
    text-align: left;
  }

  .logo {
    background: url("../../assets/images/logo.svg") center center no-repeat;
    height: 50px;
    justify-self: center;
    min-width: 234px;
  }

  .right-link {
    text-align: right;
  }

  @include mediaXs {
    .left-link a,
    .right-link a{
      font-size: var(--zen-body3-fz);
    }

    .logo {
      background-size: contain;
      min-width: 127px;
    }
  }
</style>
