<template>
  <div>
    <div
      v-for="provider in providersShow"
      :key="provider.type"
      class="oauth-container"
      :class="{'oauth-container_connect': connect}"
    >
      <template v-if="connect">
        <a
          v-if="!accounts[provider.type]"
          :href="`${oauthPrefixDomain}/oauth/${provider.type}${queryString}`"
          :class="buttonClasses(provider.type)"
        >
          <div class="oauth-button-label">
            {{ $t('auth.connect') }} {{ provider.label }}
          </div>
        </a>

        <div
          v-else
          :class="buttonClasses(provider.type)"
        >
          <div class="oauth-button-label oauth-button-label_connected">
            {{ accounts[provider.type]['name'] || $t('auth.connected') }}
          </div>
        </div>
      </template>

      <a
        v-else
        :href="`${oauthPrefixDomain}/oauth/${provider.type}${queryString}`"
        :class="buttonClasses(provider.type)"
        @click="$emit('login', provider.type)"
      >
        <span>
          {{ buttonLabel(provider.label) }}
        </span>
      </a>
    </div>

    <div
      v-show="isShort"
      class="links"
    >
      <a @click="anotherMethodsShow">
        {{ $t('labels.another_methods') }}
      </a>
    </div>
  </div>
</template>

<script>
  export default {
    emits: [
      'login',
    ],

    props: {
      accounts: {
        type: Object,
        default() {
          return {};
        },
      },

      connect: {
        type: Boolean,
        default: false,
      },

      courseId: {
        type: String,
        default: null,
      },

      onlyName: {
        type: Boolean,
        default: false,
      },

      regionId: {
        type: String,
        default: '',
      },

      schoolDomain: {
        type: String,
        default: '',
      },

      userId: {
        type: String,
        default: '',
      },

      short: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        appDomain: window.appDomain || window.location.hostname, // первый вариант для school.zenclass.ru, второй для zenclass.ru
        isShort: this.short,
      };
    },

    computed: {
      providers() {
        return [
          { label: 'Google', type: 'google', show: true },
          { label: 'VK', type: 'vkontakte', show: !this.isShort && this.$i18n.locale === 'ru' },
          { label: 'Apple', type: 'apple', show: !this.isShort },
          // { label: 'Facebook', type: 'facebook', show: !this.isShort },
          // { label: 'Github', type: 'github' },
        ];
      },

      providersShow() {
        return this.providers.filter((provider) => provider.show);
      },

      oauthPrefixDomain() {
        return `${window.location.protocol}//api.${this.appDomain}`;
      },

      queryString() {
        return `?school=${this.schoolDomain}&region_id=${this.regionId}&course_id=${this.courseId}`;
      },
    },

    methods: {
      buttonClasses(type) {
        return `oauth-button oauth-button__${type}`;
      },

      buttonLabel(label) {
        if (this.onlyName) {
          return label;
        }

        return `${this.$t('auth.continue_through')} ${label}`;
      },

      anotherMethodsShow() {
        this.isShort = false;
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../../common/sass/theme';
  @import '../../common/sass/mixins/media-queries';

  .oauth-container{
    margin-bottom: 8px;
    max-width: 100%;
    white-space: nowrap;
  }

  .oauth-container:last-child{
    margin-bottom: 0;
  }

  .oauth-button{
    position: relative;
    background: $theme-grayscale-025_900;
    border-radius: 8px;
    margin: 0 auto;
    font-style: normal;
    font-weight: var(--zen-weight-regular);
    font-size: var(--zen-body2-fz);
    line-height: 1.15;
    padding: $authBtnPadding;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $theme-typography;
  }

  .oauth-container_connect .oauth-button{
    height: var(--zen-h2-lh);
    line-height: var(--zen-h2-lh);
  }

  .oauth-button:after{
    position: absolute;
    width: 24px;
    height: 24px;
    right: 18px;
    content: '';
  }

  $providers: (
    facebook,
    vkontakte,
    google,
    apple,
  );

  @each $provider in $providers {
    .oauth-button__#{$provider}:after{
      background: #{$theme-grayscale-025_900} url("https://widget.zenclass.ru/images/icons/#{$provider}.png") center center no-repeat;
      background-size: contain;
    }

    .oauth-container_connect .oauth-button__#{$provider}:after{
      height: 32px;
      width: 32px;
    }

    .oauth-container_connect .oauth-button__#{$provider}{
      background-color: transparent;
    }

    @include retina {
      .oauth-button__#{$provider}:after{
        background-image: url("https://widget.zenclass.ru/images/icons/#{$provider}@2x.png");
      }

      .oauth-container_connect .oauth-button__#{$provider}:after{
        background-size: 32px 32px;
      }
    }
  }

  .oauth-button-label{
    background-color: $theme-grayscale-025_900;
    border-radius: 4px;
    color: $theme-typography;
    display: inline-block;
    font-size: var(--zen-body3-fz);
    font-weight: var(--zen-weight-regular);
    min-width: 176px;
    text-align: center;
    vertical-align: middle;
  }

  .oauth-button-label_connected{
    background-color: transparent;
    color: $theme-systemInfo;
    font-size: var(--zen-body2-fz);
    text-align: left;
  }

  @include mediaXs {
    .oauth-button{
      padding: $authBtnPaddingXs;
    }
  }
</style>
