/**
 * @param {string|number|null|undefined} str
 * @param {string} unit
 * @returns {undefined|string}
 */
export const convertToUnit = (str, unit = 'px') => {
  if (!str) {
    return undefined;
  }

  if (isNaN(+str)) {
    return String(str);
  }

  if (!isFinite(+str)) {
    return undefined;
  }

  return `${Number(str)}${unit}`;
};
