export const ICON_NAMES = [
  'z-alert-circle',
  'z-alert-circle-outline',
  'z-arrow-bottom-left',
  'z-arrow-bottom-right',
  'z-arrow-down',
  'z-arrow-left',
  'z-arrow-right',
  'z-arrow-top-left',
  'z-arrow-top-right',
  'z-arrow-up',
  'z-audio',
  'z-bell',
  'z-cached',
  'z-calendar-blank',
  'z-calendar-range',
  'z-cancel',
  'z-check',
  'z-check-thin',
  'z-chevron-down',
  'z-chevron-left',
  'z-chevron-right',
  'z-chevron-up',
  'z-clock-outline',
  'z-close',
  'z-cloud-off-outline',
  'z-cloud-upload',
  'z-cog',
  'z-color-fill',
  'z-content-copy',
  'z-content-paste',
  'z-credit-card',
  'z-crop',
  'z-delete-outline',
  'z-dots-vertical',
  'z-download',
  'z-drag',
  'z-exclamation',
  'z-eye-outline',
  'z-eye-off-outline',
  'z-file-document',
  'z-file-document-outline',
  'z-format-size',
  'z-history',
  'z-image',
  'z-information',
  'z-link',
  'z-lock',
  'z-format-list-bulleted',
  'z-format-list-checkbox',
  'z-format-list-checks',
  'z-format-vertical-align-bottom',
  'z-format-vertical-align-center',
  'z-format-vertical-align-top',
  'z-magnify',
  'z-megaphone',
  'z-menu',
  'z-menu-down',
  'z-menu-up',
  'z-message',
  'z-minus',
  'z-paperclip',
  'z-pause',
  'z-pencil',
  'z-play',
  'z-plus',
  'z-plus-circle',
  'z-product-card',
  'z-questionnaire',
  'z-school',
  'z-send',
  'z-shuffle',
  'z-sort-variant',
  'z-star-outline',
  'z-sync',
  'z-text-check',
  'z-text-minus',
  'z-text-plus',
  'z-ticket-percent-outline',
  'z-timeline',
  'z-upload',
  'z-video-outline',
  'z-video-player',
  'z-video-player-protected',
  'z-volume-high',
  'z-volume-off',
  'z-wallet',
  'z-xml',
];
